<footer class="footer">
    <div class="container text-muted">
        <p>&copy; Rabobank {{year}}
            | <a href="https://www.rabobank.com/en/footer/disclaimer.html" target="_blank">Disclaimer</a>
            | Privacy Policy can be found at <a href="https://www.rabobank.com/en/locate-us/americas/canada.html" target="_blank">Rabobank Canada</a>
            | <a href="https://media.rabobank.com/m/3f150ac00ab53cda/original/California-Voluntary-Carbon-Market-Disclosure-Act-Rabobank-Disclosure.pdf" target="_blank">California Voluntary Carbon Market Disclosure Act: Rabobank Disclosure</a>
            </p>
        <p>This site uses cookies to provide for technical functionality as well as to make our website more enjoyable and relevant
            to you. By continuing your visit on the website, you consent to the use of these <a class="clickable"
            tabindex="0" (click)="showCookieModal = true">cookies</a>.
        </p>
        <p>We are glad to provide you with additional information. We want you to know that
            our policy is to maintain your private information as confidential. Please note that we will not
            transmit personal information such as your date of birth to you via e-mail, and we recommend that
            you do not e-mail such information to us. Please contact us directly by phone at (888) 395-8505
            with any questions regarding your account involving confidential information.</p>
    </div>
</footer>
<p-dialog [(visible)]="showCookieModal" [dismissableMask]="true" [modal]="true">
    <p>By continuing your visit on the website, you consent to the use of these cookies</p>
    <p-table [value]="cookieData">
        <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let col of columns">{{col.header}}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
            <tr>
                <td *ngFor="let col of columns" [innerHTML]="row[col.field]"></td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>